import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {RsaId} from "../rsa.id.validator";
import {DropDownValuesVO} from "../select.vo";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
}

@Injectable({
    providedIn: 'root'
})

export class SFSummaryService {
    constructor(private http: HttpClient) {}
    public getPricing(request: SimplePricingRequestVO): Observable<CoverOptionsVO> {
        return this.http.post<CoverOptionsVO>('/dl/data/pricing/simple-for-sf', request, httpOptions);
    }

    public recalculatePricing(request: RepriceRequestVO): Observable<Reprice> {
        return this.http.post<Reprice>('/dl/data/pricing/simple-for-sf-reprice', request, httpOptions);
    }

    public revertPricing(request: RepriceRequestVO): Observable<DependantVO[]> {
        return this.http.post<DependantVO[]>('/dl/data/pricing/simple-revert-for-sf', request, httpOptions);
    }
}

export class Reprice {
    premium: number;
}

export class RepriceRequestVO {
    spId: string;
    benefits: DependantVO[];
    product: ProductPricingRequestVO[];
    constructor(spId: string, benefits: DependantVO[], product: ProductPricingRequestVO[] = [new ProductPricingRequestVO("")]) {
        this.spId = spId;
        this.benefits = benefits;
        this.product = product;
    }
}




export class ProductMapping {
    public product: Map<string, ProductOptionVO[]> = new Map<string, ProductOptionVO[]>();

    constructor() {
        this.populateProducts();
    }

    private populateProducts(): void {
        this.product.set('SF',
            [
                new ProductOptionVO (
                    'SF_S',
                    'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Max: 65',
                    'Spouse',
                    1
                ),
                new ProductOptionVO (
                    'SF_C',
                    'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 21',
                    'Child',
                    8
                ),
                new ProductOptionVO (
                    'SF_E',
                    '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members. Age Max: 75',
                    'Extended',
                    8
                )
            ]
        );
        this.product.set('CE',
            [
                new ProductOptionVO (
                    'CE_S',
                    'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Max: 65',
                    'Spouse',
                    1
                ),
                new ProductOptionVO (
                    'CE_C',
                    'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 21',
                    'Child',
                    8
                ),
                new ProductOptionVO (
                    'CE_E',
                    '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members. Age Max: 75',
                    'Extended',
                    8
                )
            ]
        );
        this.product.set('SFF',
            [
                new ProductOptionVO (
                    'SFF_S',
                    'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Max: 65',
                    'Spouse',
                    1
                ),
                new ProductOptionVO (
                    'SFF_C',
                    'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 21',
                    'Child',
                    8
                ),
                new ProductOptionVO (
                    'SFF_E',
                    '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members. Age Max: 75',
                    'Extended',
                    8
                )
            ]
        );
        this.product.set('SFFL',
            [
                new ProductOptionVO (
                    'SFFL_S',
                    'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Max: 65',
                    'Spouse',
                    1
                ),
                new ProductOptionVO (
                    'SFFL_C',
                    'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 21',
                    'Child',
                    8
                ),
                new ProductOptionVO (
                    'SFFL_E',
                    '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members. Age Max: 75',
                    'Extended',
                    8
                )
            ]
        );
        this.product.set('SFFC',
            [
                new ProductOptionVO (
                    'SFFC_S',
                    'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Max: 65',
                    'Spouse',
                    1
                ),
                new ProductOptionVO (
                    'SFFC_C',
                    'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 21',
                    'Child',
                    8
                ),
                new ProductOptionVO (
                    'SFFC_E',
                    '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members. Age Max: 75',
                    'Extended',
                    8
                )
            ]
        );
        this.product.set('SFFCS',
            [
                new ProductOptionVO (
                    'SFFCS_S',
                    'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Max: 65',
                    'Spouse',
                    1
                ),
                new ProductOptionVO (
                    'SFFCS_C',
                    'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 21',
                    'Child',
                    8
                ),
                new ProductOptionVO (
                    'SFFCS_E',
                    '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members. Age Max: 75',
                    'Extended',
                    8
                )
            ]
        );
    }

    public getProduct(prod: string):  ProductOptionVO[] {
        return this.product.get(prod);
    }
}

export class ProductPricingRequestVO {
    productCode: string;
    version : string;
    coverAmount: number;

    constructor(productCode: string , version: string = '20250401') {
        this.productCode = productCode;
        this.version = version;
    }
}

export class SimplePricingRequestVO {
    spId: string;
    product: ProductPricingRequestVO[];
    benefits: DependantVO[];
    age: number;
    main: boolean;

    constructor(spId: string, product: ProductPricingRequestVO[], benefits: DependantVO[], age: number, main: boolean) {
        this.spId = spId;
        this.product = product;
        this.benefits = benefits;
        this.age = age;
        this.main = main;
    }
}





export class DependantVO {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    id_or_dob: string;
    age: number;
    rel: string;
    pricing_version: string;
    product: string;
    product_master_id: string;
    pricing: any;
    benefit_amount: number;
    premium_amount: number;
    deleting: boolean;
    gender: string;
    addons?: any;
}

export class CoverOptionsVO {
    status: string;
    cover: number;
    min: number;
    max: number;
    step: number;
    error: string;
    product_master_id: string;
    pricing: any;
    options: {
        cover: number;
        premium: number;
    }[];

    constructor(status?: string) {
        this.status = status
    }
}

export class ProductOptionVO {
    code: string;
    description: string;
    rel: string;
    max: number;
    constructor(code: string, desc: string, rel: string, max: number) {
        this.code = code;
        this.description = desc;
        this.rel = rel;
        this.max = max;
    }
}

export class ChangeEvent {
    rsaId: RsaId;
    product: string;

    constructor(rsaId: RsaId, product: string) {
        this.rsaId = rsaId;
        this.product = product;
    }
}

export class ModalData {
    spId: string;
    benefitId: string;
    leadName: string;
    edit: boolean;
    main: boolean;
    products: DropDownValuesVO[];
    dependant: DependantVO;
    dependants: DependantVO[];
}







