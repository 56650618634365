import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {RsaId} from '../rsa.id.validator';
import {DropDownValuesVO} from '../select.vo';
import {isNullOrUndefined} from 'util';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})

export class LifecheqSummaryService {
    constructor(private http: HttpClient) {}

    public getPricing(request: SimplePricingRequestVO): Observable<CoverOptionsVO> {
        return this.http.post<CoverOptionsVO>('/dl/data/pricing/simple-for-lpf', request, httpOptions);
    }

    public recalculatePricing(request: RepriceRequestVO): Observable<Reprice[]> {
        return this.http.post<Reprice[]>('/dl/data/pricing/simple-for-lpf-reprice', request, httpOptions);
    }
}

export class Reprice {
    premium: number;
}

export class RepriceRequestVO {
    id?: string;
    spId: string;
    benefits: LPFDependantVO[];
    constructor(spId: string, benefits: LPFDependantVO[]) {
        this.spId = spId;
        this.benefits = benefits;
    }
}

export class ProductMapping {
    public product: Map<string, ProductOptionVO[]> = new Map<string, ProductOptionVO[]>();

    constructor() {
        this.populateProducts();
    }

    private populateProducts(): void {
        this.product.set('LPF',
            [
                new ProductOptionVO (
                    'LPF_S',
                    'A <b>Spouse</b> Age Max: 85',
                    'Spouse',
                    1
                ),
                new ProductOptionVO (
                    'LPF_C',
                    'A <b>Child</b> Age Max: 25',
                    'Child',
                    999
                ),
                new ProductOptionVO (
                    'LPF_P',
                    '<b>Parents / In-Laws</b> Age Max: 85',
                    'Parents / In-Laws',
                    4
                ),
                new ProductOptionVO (
                    'LPF_E',
                    '<b>Extended Family</b> Age Max: 85',
                    'Extended Family',
                    999
                )
            ]
        );
    }

    public getProduct(prod: string):  ProductOptionVO[] {
        return this.product.get(prod);
    }
}

export class ProductPricingRequestVO {
    productCode: string;
    version: string;
    coverAmount: number;

    constructor(productCode: string, version: string = 'DEFAULT') {
        this.productCode = productCode;
        this.version = version;
    }
}

export class SimplePricingRequestVO {
    spId: string;
    product: ProductPricingRequestVO[];
    benefits: LPFDependantVO[];
    age?: number = null;
    addons?: string[];

    constructor(spId: string, product: ProductPricingRequestVO[], benefits: LPFDependantVO[], age?: number, addons?: string[]) {
        this.spId = spId;
        this.product = product;
        this.benefits = benefits;
        this.age = age;
        this.addons = addons;
    }
}

export class LPFDependantVO {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    id_or_dob: string;
    age: number;
    rel: string;
    product: string;
    product_master_id: string;
    pricing: any;
    benefit_amount: number;
    premium_amount: number;
    deleting: boolean;
    gender: string;
    premium_escalation = 0.05;
    benefit_escalation = 0.03;
    payout_annuity_amount: number;
    payout_term: number;
    payout_type: string;
    addons: any;
    pricing_version: string;
    orig_waiting_period_started;
    orig_waiting_period_served;
    orig_waiting_period;
}

export class CoverOptionsVO {
    status: string;
    cover: number;
    min: number;
    max: number;
    step: number;
    error: string;
    product_master_id: string;
    pricing: any;
    options: {
        cover: number;
        premium: number;
    }[];

    constructor(status?: string) {
        this.status = status;
    }
}

export class ProductOptionVO {
    code: string;
    description: string;
    rel: string;
    max: number;
    constructor(code: string, desc: string, rel: string, max: number) {
        this.code = code;
        this.description = desc;
        this.rel = rel;
        this.max = max;
    }
}

export class ChangeEvent {
    rsaId: RsaId;
    product: string;

    constructor(rsaId: RsaId, product: string) {
        this.rsaId = rsaId;
        this.product = product;
    }
}

export class ModalData {
    spId: string;
    benefitId: string;
    leadName: string;
    edit: boolean;
    main: boolean;
    products: DropDownValuesVO[];
    dependant: LPFDependantVO;
    dependants: LPFDependantVO[];
}
