import {Log} from 'ng2-logger/client';
import {Component, EventEmitter, Inject} from '@angular/core';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../rsa.id.validator';
import {BehaviorSubject} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    CoverOptionsVO,
    SFSummaryService,
    ProductPricingRequestVO,
    ModalData,
    ProductMapping,
    ChangeEvent,
    ProductOptionVO,
    SimplePricingRequestVO, DependantVO
} from "./sf-summary.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorVO} from "../error/error.vo";
import {DLUtil} from "../dl.util";
import {MatSliderChange} from "@angular/material/slider";
import {DropDownValuesVO} from "../select.vo";

const log = Log.create('SF-Dependant-Modal');
@Component({
    selector: 'sf-dependant-modal',
    templateUrl: './sf-dependant-modal.component.html'
})
export class SFDependantModalComponent implements RsaIdValidationCallback{

    form: FormGroup;
    coverOptions$: BehaviorSubject<CoverOptionsVO>;
    sliderPoints: any;
    changeEvents$: EventEmitter<ChangeEvent>;
    isValid = false;
    isBusy = false;
    benefit_amount: number;
    premium_amount: number;
    product_master_id: string;
    pricing: any;
    rsaId = new RsaId();
    options: ProductOptionVO[];

    constructor(private dialogRef: MatDialogRef<SFDependantModalComponent>,
                private fb: FormBuilder,
                private summaryService: SFSummaryService,
                @Inject(MAT_DIALOG_DATA) public data: ModalData){
        log.info("in constructor")
        this.setupForm(data.main)
        if(data.main){
            this.fetchCoverOptions(null, data.dependant.product);
        }
        if (!isNullOrUndefined(data.dependant.benefit_amount)) {
            this.benefit_amount = data.dependant.benefit_amount;
        }
        if (!isNullOrUndefined(data.dependant.premium_amount)) {
            this.premium_amount = data.dependant.premium_amount;
        }
        let productList: DropDownValuesVO[] = [];
        const count: Map<string, boolean> = this.canAdd();
        data.products.forEach((item: DropDownValuesVO) =>{
            item.disabled = false;
            if(!this.displayList().includes(item.display)){
                item.disabled = true;
            }
            else{
                item.disabled = item.disabled || count.get(item.display);
                productList.push(item);
            }
        });
        data.products = productList;
    }

    setupForm(main: boolean): void {
        this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();
        if (main) {
            log.info('Main Member');
            this.form = this.fb.group({x: ['']});
        } else {
            log.info('Dependant');
            this.form = this.fb.group({
                product: [this.data.dependant.product, [Validators.required]],
                first_name: [this.data.dependant.first_name, [Validators.required]],
                last_name: [this.data.dependant.last_name, [Validators.required]],
                gender: [this.data.dependant.gender, [Validators.required]],
                id_or_dob: [this.data.dependant.id_or_dob, [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]]
            });
            this.form.get('first_name').valueChanges.pipe(debounceTime(450)).subscribe();
            this.form.get('last_name').valueChanges.pipe(debounceTime(450)).subscribe();
            this.form.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(() => {
                this.rsaId.setId(this.form.get('id_or_dob').value);
                if (!isNullOrUndefined(this.rsaId.getGender())) {
                    this.form.get('gender').setValue((this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
                }
                this.data.products.forEach((item: DropDownValuesVO) => {
                    let ageValidation: boolean = false;
                    if(item.display === 'Child')
                        ageValidation = this.ageValidation(this.rsaId.getAge(), 21);
                    else if(item.display === 'Extended Family')
                        ageValidation = this.ageValidation(this.rsaId.getAge(), 75);
                    else if(item.display === 'Spouse')
                        ageValidation = this.ageValidation(this.rsaId.getAge(), 65);
                    item.disabled = item.disabled || ageValidation;
                });
            });
            this.form.get('product').valueChanges.subscribe(() => this.productSelected());
            // Initialize an Observable for consolidated, debounced change events
            this.changeEvents$.pipe(
                debounceTime(650),
                distinctUntilChanged((a, b) => {
                    return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product;
                })
            ).subscribe(
                // Update premiums for change events
                ($event) => this.fetchCoverOptions($event.rsaId, $event.product)
            );
        }
    }

    public fetchCoverOptions(rsaId: RsaId, product: string): void {
        if(!this.data.main){
            if (isNullOrUndefined(product) || product.length === 0) {
                this.invalidateSlider('no product selected');
                return;
            }
            if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 8 && !rsaId.isValid())) {
                this.invalidateSlider('id is invalid');
                return;
            }
        }
        log.info('loading cover options for ' + product);
        this.isValid = false;
        this.form.disable({onlySelf: true, emitEvent: false});
        this.coverOptions$.next(new CoverOptionsVO('loading'));
        const req =
            new SimplePricingRequestVO(this.data.spId,[new ProductPricingRequestVO(product)], this.data.dependants as DependantVO[], rsaId !== null ? rsaId.getAge() : null, this.data.main);
        this.summaryService.getPricing(req).subscribe( (res: CoverOptionsVO) => {
            this.updateCoverOptions(product, res);
            this.form.enable({onlySelf: true, emitEvent: false});
        },  (error: any) => {
            log.error('Error getting Dependant Info', error);
            this.form.enable({onlySelf: true, emitEvent: false});
            const coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = ErrorVO.toErrorVO(error).message;
            this.coverOptions$.next(coverOptions);
        });

    }

    updateCoverOptions(product: string, res: CoverOptionsVO){
        if(isNullOrUndefined(res))
            return;
        if (res.hasOwnProperty('error')) {
            const coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = res.error;
            this.isValid = false;
            this.coverOptions$.next(coverOptions);
        } else {
            res.status = 'valid'
            for (let p of res.options) {
                if (!isNullOrUndefined(this.benefit_amount) && p.cover === this.benefit_amount) {
                    this.benefit_amount = p.cover;
                    res.cover = p.cover;
                    this.premium_amount = p.premium;
                }
            }
            this.sliderPoints = res.options;
            this.product_master_id = res.product_master_id;
            this.pricing = res.pricing;
            this.isValid = false;
            this.coverOptions$.next(res);

        }

    }


    private invalidateSlider(message: string) {
        log.info(message);
        this.coverOptions$.next(new CoverOptionsVO('invalid'));
    }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    productSelected():void{
        const rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        const product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product));
    }

    get title(): string {
        if (this.isDependant) {
            return (this.data.edit ? 'Edit' : 'Add') + ' additional life';
        }
        return 'Edit Main Member';
    }

    get saveButtonText(): string {
        return this.data.edit ? 'Save' : 'Add';
    }

    get isDependant(): boolean {
        return !this.data.main;
    }

    selectionChange(id: string) {
    }

    get leadName(): string {
        return this.data.leadName;
    }

    cancel(): void {
        this.dialogRef.close('cancel');
    }

    onIdValidationChange(rsaId: RsaId): void {
        if (!isNullOrUndefined(this.form) &&
            !isNullOrUndefined(this.form.get('product')) &&
            !isNullOrUndefined(this.form.get('product').value) &&
            !isNullOrUndefined(this.form.get('gender').value)) {
            const product = this.form.get('product').value as string;
            this.changeEvents$.emit(new ChangeEvent(rsaId, product));
        }
    }

    get productHint(): string {
        const product = this.form.get('product').value;
        if(!isNullOrUndefined(product))
            this.options = new ProductMapping().getProduct(this.determineProductGroup(product));
        else
            this.options = new ProductMapping().getProduct(this.determineProductGroup(this.data.dependants[0].product))
        if(!isNullOrUndefined(product) && !isNullOrUndefined(this.options))
            return this.getProductDescription(product);
        return null;
    }

    public getProductDescription(product: string): string {
        for(let p of this.options){
            if(p.code === product)
                return p.description;
        }
        return null;
    }

    private determineProductGroup(product: string){
        return product.split('_')[0];
    }

    updateCover(val: MatSliderChange): void {
        this.benefit_amount = val.value;
        this.premium_amount = this.findPremiumForCover(val.value);
        this.isValid = val.value !== 0;
        this.isValid = this.premium_amount !== 0;
        this.form.markAsDirty();
    }

    private findPremiumForCover(cover): number {
        let premium = 0;
        this.sliderPoints.forEach((opt) => {
            if (cover === opt.cover) {
                premium = opt.premium;
            }
        });
        return premium;
    }

    get disableSave(): boolean {
        return !this.isValid || this.form.pristine || this.form.invalid;
    }

    save(): void {
        this.isBusy = true;
        const data = new ModalData();
        DLUtil.copyFields(this.data, data);
        data.dependant = new DependantVO();
        DLUtil.copyFields(this.data.dependant, data.dependant);
        DLUtil.copyFields(this.form.value, data.dependant);
        data.dependant.benefit_amount = this.benefit_amount;
        data.dependant.premium_amount = this.premium_amount;
        data.dependant.product_master_id = this.product_master_id;
        data.dependant.pricing = this.pricing;
        data.dependant.age = this.rsaId.getAge();
        this.dialogRef.close(data);
    }

    private displayList(): string[]{
        return ['Child', 'Spouse', 'Extended Family']
    }

    private canAdd(): Map<string, boolean> {
        let count = new Map<string, boolean>();
        let spouse = 0;
        let total = 0;
        let child = 0;
        count.set('Child', true);
        count.set('Spouse', false);
        count.set('Extended Family', true);
        for(let dep of this.data.dependants){
            switch (dep.rel){
                case 'Extended Family':
                    ++total;
                    break;
                case 'Child':
                    ++child;
                    break;
                case 'Spouse':
                    ++spouse;
                    break;
                default:
                    break;
            }
        }
        if (spouse === 1)
            count.set('Spouse', true);
        if(total < 8)
            count.set('Extended Family', false);
        if(child < 8)
            count.set('Child', false);
        return count;
    }

    private ageValidation(age: number, limit: number): boolean{
        if (age > limit){
            return true;
        }
        else if(age <= limit && age > 0)
            return false;
    }


}
