import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter, Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {LPFDependantVO, ModalData} from './lifecheq-summary.service';
import {MatDialog} from '@angular/material';
import {DropDownValuesVO} from '../select.vo';
import {MatDialogRef} from '@angular/material/dialog';
import {isNullOrUndefined} from 'util';
import {LifecheqDependantModalComponent} from './lifecheq-dependant-modal.component';
import {Log} from 'ng2-logger/client';
import {FormGroup} from '@angular/forms';
import {PolicyService} from '../../profile/policy/policy.service';
import {AddonServerVO} from '../base-product/base-products.service';
import {AddonVO} from '../../profile/profile.service';
import {RsaId} from '../rsa.id.validator';
import {BehaviorSubject, Observable} from "rxjs";
import {distinctUntilChanged, tap} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";

const log = Log.create('Lifecheq-Summary');
@Component({
    templateUrl: 'lifecheq-base-summary.component.html',
    selector: 'lifecheq-base-summary'
})
export class LifecheqBaseSummaryComponent implements OnInit, AfterViewChecked {
    @Input()
    leadName: string;
    @Input()
    spId: string;
    @Input()
    benefitId: string;
    @Input()
    dependants: LPFDependantVO[];
    @Input()
    products: DropDownValuesVO[];
    @Input()
    eventCategory: string;
    @Output()
    add = new EventEmitter<LPFDependantVO>();
    @Output()
    edit = new EventEmitter<any>();
    @Output()
    remove = new EventEmitter<LPFDependantVO>();
    private dialogRef: MatDialogRef<LifecheqDependantModalComponent>;

    addonsList = [];
    payoutTypeList = [];
    payoutTermList = [];
    payoutAnnuityAmountList = [];
    monthlySupportSelected = false;
    payoutTerm = 12;
    payoutType = 'annuity';
    payoutAnnuityAmount = 1000;
    mainDependant: LPFDependantVO;
    spouseDependant: LPFDependantVO;
    private errorSubject = new BehaviorSubject<string[]>([]);
    errors$: Observable<string[]> = this.errorSubject.asObservable();

    constructor(private dialog: MatDialog, @Inject(DOCUMENT) private document) {

    }

    public static removeDependant(dependants: LPFDependantVO[], id: string): LPFDependantVO[] {
        const list: LPFDependantVO[] = [];
        dependants.forEach((d) => {
            if (d.id !== id) {
                list.push(d);
            }
        });
        return list;
    }

    ngAfterViewChecked() {
        this.errors$.subscribe(value => {
            this.document.querySelector('[data-event-name=\'Action editPolicy-0 Next\']').disabled = value.length !== 0;
        });
    }

    ngOnInit() {
        console.log('=============================================');
        console.log(this.dependants);
        console.log('=============================================');

        this.addonsList = [
            {
                'id': 'KP_LPF_UCB2_001',
                'code': 'LPF_UCB2',
                'name': 'Pantheon Funeral Cover - Upgraded Cash Back 2',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_UCB1_001',
                'code': 'LPF_UCB1',
                'name': 'Pantheon Funeral Cover - New Year Benefit',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_S_MS_001',
                'code': 'LPF_S_MS',
                'name': 'Pantheon Funeral Cover - Spouse Monthly Support',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_RB_001',
                'code': 'LPF_RB',
                'name': 'Pantheon Funeral Cover - Retirement Benefit',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_PW1_001',
                'code': 'LPF_PW1',
                'name': 'Pantheon Funeral Cover - Premium Waiver',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_UCB3_001',
                'code': 'LPF_UCB3',
                'name': 'Pantheon Funeral Cover - Upgraded Cash Back 3',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_REPAT_001',
                'code': 'LPF_REPAT',
                'name': 'Pantheon Funeral Cover - Repatriation Benefit',
                'type': 'primary',
                checked: true,
                disabled: true
            },
            {
                'id': 'KP_LPF_M_MS_001',
                'code': 'LPF_M_MS',
                'name': 'Pantheon Funeral Cover - Main Member Monthly Support',
                'type': 'primary',
                checked: false,
                disabled: false
            }
        ];
        this.payoutTypeList = [
            {
                name: 'Annuity',
                value: 'annuity'
            },
            {
                name: 'Blended',
                value: 'blended'
            }
        ];
        this.payoutTermList = [
            {
                name: '12 Months',
                value: 12
            },
            {
                name: '24 Months',
                value: 24
            },
            {
                name: '36 Months',
                value: 36
            }
        ];
        this.payoutAnnuityAmountList = [
            {
                name: 'R1000',
                value: 1000
            },
            {
                name: 'R2000',
                value: 2000
            }
        ];

        this.mainDependant = this.dependants.find(dependant => dependant.product === 'LPF');
        this.spouseDependant = this.dependants.find(dependant => dependant.product === 'LPF_S');

        const mmId = new RsaId();

        mmId.setId(this.mainDependant.id_or_dob);

        this.mainDependant.age = mmId.getAge();

        if (!this.dependants.some(dependant => dependant.product === 'LPF_S')) {
            this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].disabled = true;
            this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].checked = false;
        }

        this.mainDependant.addons.forEach(addon => {
            this.addonsList.forEach((addonItem, index) => {
                if (addonItem.code === addon.addon_code) {
                    this.addonsList[index].checked = true;

                    if (addonItem.code === 'LPF_UCB1') {
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB2')].disabled = true;
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB3')].disabled = true;
                    } else if (addonItem.code === 'LPF_UCB2') {
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB1')].disabled = true;
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB3')].disabled = true;
                    } else if (addonItem.code === 'LPF_UCB3') {
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB1')].disabled = true;
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB2')].disabled = true;
                    }

                    if (addonItem.code === 'LPF_RB') {
                        if ((this.mainDependant.age + 1) > 65) {
                            // this.addError('You\'re not eligible for the Retirement Benefit');

                            this.mainDependant.addons = this.mainDependant.addons.filter(aI => aI.addon_code !== 'LPF_RB');

                            this.addonsList[index].disabled = true;
                            this.addonsList[index].checked = false;
                        }
                    }

                    if (addonItem.code === 'LPF_PW1') {
                        if (this.mainDependant.age === 65 || (this.mainDependant.age + 1) > 63) {
                            this.addonsList[index].disabled = true;
                            this.addonsList[index].checked = false;

                            this.mainDependant.addons = this.mainDependant.addons.filter(aI => aI.addon_code !== 'LPF_PW');
                        }
                    }
                }
            });
        });

        if ((this.mainDependant.payout_type !== 'lump-sum')) {
            this.monthlySupportSelected = true;

            this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_M_MS')].checked = true;
            this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].disabled = true;

            this.payoutType = this.mainDependant.payout_type;
            this.payoutTerm = this.mainDependant.payout_term;
            this.payoutAnnuityAmount = this.mainDependant.payout_annuity_amount;
        } else if (!isNullOrUndefined(this.spouseDependant) && this.spouseDependant.payout_type !== 'lump-sum') {
            this.monthlySupportSelected = true;

            this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].checked = true;
            this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_M_MS')].disabled = true;

            this.payoutType = this.spouseDependant.payout_type;
            this.payoutTerm = this.spouseDependant.payout_term;
            this.payoutAnnuityAmount = this.spouseDependant.payout_annuity_amount;
        }

        // this.checkMaxCover();
        this.checkCashBackAddon();
    }

    onChangeAddon({ source, checked }) {
        const addonVO = new AddonServerVO();

        console.log(source, checked);

        if (checked) {
            if (source.value === 'LPF_UCB1') {
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB2')].disabled = true;
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB3')].disabled = true;
            } else if (source.value === 'LPF_UCB2') {
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB1')].disabled = true;
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB3')].disabled = true;
            } else if (source.value === 'LPF_UCB3') {
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB1')].disabled = true;
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB2')].disabled = true;
            }

            if (source.value === 'LPF_M_MS' || source.value === 'LPF_S_MS') {
                this.monthlySupportSelected = true;

                if (source.value === 'LPF_M_MS') {
                    this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].disabled = true;

                    this.mainDependant.payout_type = this.payoutType;
                    this.mainDependant.payout_term = this.payoutTerm;
                    this.mainDependant.payout_annuity_amount = this.payoutAnnuityAmount;
                } else if (source.value === 'LPF_S_MS') {
                    this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_M_MS')].disabled = true;

                    this.spouseDependant.payout_type = this.payoutType;
                    this.spouseDependant.payout_term = this.payoutTerm;
                    this.spouseDependant.payout_annuity_amount = this.payoutAnnuityAmount;
                }

                if (!this.checkMonthlySupportAddonValid(source.value)) {
                    if (source.value === 'LPF_M_MS') {
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].disabled = true;
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].checked = false;
                    } else if (source.value === 'LPF_S_MS') {
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_M_MS')].disabled = true;
                        this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_M_MS')].checked = false;
                    }

                    this.mainDependant.addons = this.mainDependant.addons.filter(addon => addon.addon_code !== source.value);
                }
            }

            if (source.value !== 'LPF_M_MS' && source.value !== 'LPF_S_MS') {
                addonVO.sales_process_id = this.spId;
                // addonVO.id = null;
                addonVO.type = 'addon';
                addonVO.name = this.addonsList.find(addonItem => addonItem.code === source.value).name;
                addonVO.product_code = 'LPF';
                addonVO.product_master_id = this.addonsList.find(addonItem => addonItem.code === source.value).id;
                addonVO.addon_code = source.value;
                addonVO.benefit_amount = 0;
                addonVO.premium_amount = 0;
                addonVO.addon_version = this.mainDependant.pricing_version;

                this.mainDependant.addons = [...this.mainDependant.addons, addonVO];
            }

            this.checkCashBackAddon();
        } else if (!checked) {
            if (source.value === 'LPF_UCB1') {
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB2')].disabled = false;
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB3')].disabled = false;
            } else if (source.value === 'LPF_UCB2') {
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB1')].disabled = false;
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB3')].disabled = false;
            } else if (source.value === 'LPF_UCB3') {
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB1')].disabled = false;
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_UCB2')].disabled = false;
            }

            if (source.value === 'LPF_M_MS') {
                if (this.dependants.some(dependant => dependant.product === 'LPF_S')) {
                    this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].disabled = false;
                }

                this.mainDependant.payout_type = 'lump-sum';
                this.mainDependant.payout_term = 0;
                this.mainDependant.payout_annuity_amount = null;
            } else if (source.value === 'LPF_S_MS') {
                this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_M_MS')].disabled = false;

                this.spouseDependant.payout_type = 'lump-sum';
                this.spouseDependant.payout_term = 0;
                this.spouseDependant.payout_annuity_amount = null;
            }

            if (source.value === 'LPF_M_MS' || source.value === 'LPF_S_MS') {
                this.monthlySupportSelected = false;
            }

            this.mainDependant.addons = this.mainDependant.addons.filter(addon => addon.addon_code !== source.value);

            this.checkCashBackAddon();
        }

        console.log('=============================================');
        console.log(this.mainDependant);
        // console.log(this.spouseDependant);
        console.log('=============================================');

        this.edit.emit({main: true, dependant: this.mainDependant});

        if (!isNullOrUndefined(this.spouseDependant)) {
            this.edit.emit({main: false, dependant: this.spouseDependant});
        }

        this.remove.emit(null);
    }

    onMonthlySupportOptionChange({source, value}, option) {
        switch (option) {
            case 'payoutType': {
                this.payoutType = value;
            }
                break;
            case 'payoutTerm': {
                this.payoutTerm = value;
            }
                break;
            case 'payoutAnnuityAmount': {
                this.payoutAnnuityAmount = value;
            }
                break;
        }

        if (this.addonsList.find(addon => addon.code === 'LPF_M_MS').checked) {
            if (this.checkMonthlySupportAddonValid('LPF_M_MS')) {
                this.removeError('Total monthly support can\'t be more than the cover amount');

                this.mainDependant.payout_type = this.payoutType;
                this.mainDependant.payout_term = this.payoutTerm;
                this.mainDependant.payout_annuity_amount = this.payoutAnnuityAmount;
            }

            console.log('=============================================');
            console.log(this.mainDependant);
            console.log('=============================================');
        } else if (this.addonsList.find(addon => addon.code === 'LPF_S_MS').checked) {
            this.removeError('Total monthly support can\'t be more than the cover amount');

            if (this.checkMonthlySupportAddonValid('LPF_S_MS')) {
                this.spouseDependant.payout_type = this.payoutType;
                this.spouseDependant.payout_term = this.payoutTerm;
                this.spouseDependant.payout_annuity_amount = this.payoutAnnuityAmount;
            }

            console.log('=============================================');
            console.log(this.spouseDependant);
            console.log('=============================================');
        }

        this.edit.emit({main: true, dependant: this.mainDependant});
        this.edit.emit({main: false, dependant: this.spouseDependant});
    }

    public openModal(dependant: LPFDependantVO, edit: boolean, main: boolean): void {
        const data: ModalData = {
            spId: this.spId,
            benefitId: this.benefitId,
            leadName: this.leadName,
            edit: edit,
            main: main,
            dependant: dependant,
            products: this.products,
            dependants: this.dependants
        };
        this.dialogRef = this.dialog.open(LifecheqDependantModalComponent,
            {
                disableClose: true,
                panelClass: 'dl-modal',
                maxWidth: '99vw',
                position: {top: '20px'},
                data: data
            }
        );
        this.dialogRef.beforeClose().subscribe((res: any) => {
            console.log('RES', res);
            if (res instanceof ModalData) {
                if (res.dependant.product === 'LPF_S') {
                    this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].disabled = false;
                }

                if (['LPF_S', 'LPF_C', 'LPF_P', 'LPF_E'].includes(res.dependant.product)) {
                    res.dependant.payout_type = 'lump-sum';
                    res.dependant.payout_term = 0;
                    res.dependant.payout_annuity_amount = null;
                    res.dependant.orig_waiting_period_served = this.mainDependant.orig_waiting_period_served;
                    res.dependant.orig_waiting_period = this.mainDependant.orig_waiting_period;
                    res.dependant.orig_waiting_period_started = this.mainDependant.orig_waiting_period_started;
                    res.dependant.pricing_version = this.mainDependant.pricing_version;
                    res.dependant.addons = [];
                    res.dependant.pricing.pricing_version = this.mainDependant.pricing_version;
                    res.dependant.pricing.included = true;
                    res.dependant.pricing.starting_age_year = res.dependant.age;
                    res.dependant.pricing.sum_assured_increase = 0.03;
                    res.dependant.pricing.premium_increase = 0.05;
                }

                if (res.dependant.product === 'LPF_S') {
                    this.spouseDependant = res.dependant;
                }

                // res.dependant = dependant;

                if (res.edit) {
                    console.log(res);
                    this.edit.emit({main: res.main, dependant: res.dependant});
                } else {
                    console.log(res);
                    this.add.emit(res.dependant);
                }

                // this.checkMaxCover();
            }
        });
    }

    addError(value) {
        this.errorSubject.next([...this.errorSubject.value.filter(error => error !== value), value]);
    }

    removeError(value) {
        this.errorSubject.next(this.errorSubject.value.filter(error => error !== value));
    }

    checkMaxCover() {
        let totalCover = 0;

        this.dependants.forEach(dependantVal => totalCover += dependantVal.benefit_amount);

        if (totalCover > 100000) {
            this.addError('Total cover cannot exceed R100,000');
        } else {
            this.removeError('Total cover cannot exceed R100,000');
        }
    }

    checkCashBackAddon() {
        if (this.mainDependant.addons.some(addon => addon.addon_code === 'LPF_UCB1' || addon.addon_code === 'LPF_UCB2' || addon.addon_code === 'LPF_UCB3')) {
            this.removeError('At least one Upgraded Cash Back benefit is required');
        } else {
            this.addError('At least one Upgraded Cash Back benefit is required');
        }
    }

    checkMonthlySupportAddonValid(addonCode) {
        if ((addonCode === 'LPF_M_MS' && this.mainDependant.benefit_amount < this.payoutTerm * this.payoutAnnuityAmount) || (addonCode === 'LPF_S_MS' && this.spouseDependant.benefit_amount < this.payoutTerm * this.payoutAnnuityAmount)) {
            this.addError('Total monthly support can\'t be more than the cover amount');

            return false;
        }

        return true;
    }

    clickAdd(): void {
        this.openModal(new LPFDependantVO(), false, false);
    }

    clickEdit(dependant: LPFDependantVO, main: boolean) {
        this.openModal(dependant, true, main);
    }

    clickRemove(dependant: LPFDependantVO) {
        dependant.deleting = true;
        console.log('REMOVE DEPENDENT BASE SUMMARY ONE');

        if (dependant.product === 'LPF_S') {
            this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].disabled = true;
            this.addonsList[this.addonsList.findIndex(aI => aI.code === 'LPF_S_MS')].checked = false;
            this.monthlySupportSelected = false;

            this.mainDependant.addons = this.mainDependant.addons.filter(addon => addon.addon_code !== dependant.product);
        }

        console.log('REMOVE DEPENDENT BASE SUMMARY');

        this.remove.emit(dependant);
    }

    getBadge(d: LPFDependantVO): string {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel.substr(0, 1);
        }
        return '?';
    }

    getHint(d: LPFDependantVO): string {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel;
        }
        return 'Unknown';
    }

    get totalPremium(): number {
        let total = 0;
        if (!isNullOrUndefined(this.dependants)) {
            this.dependants.forEach((d) => {
                total += d.premium_amount;
            });
        }
        return total;
    }


}
