import { AfterViewChecked, EventEmitter, OnInit } from '@angular/core';
import { LPFDependantVO, ModalData } from './lifecheq-summary.service';
import { MatDialog } from '@angular/material';
import { isNullOrUndefined } from 'util';
import { LifecheqDependantModalComponent } from './lifecheq-dependant-modal.component';
import { Log } from 'ng2-logger/client';
import { AddonServerVO } from '../base-product/base-products.service';
import { RsaId } from '../rsa.id.validator';
import { BehaviorSubject } from "rxjs";
var log = Log.create('Lifecheq-Summary');
var LifecheqBaseSummaryComponent = /** @class */ (function () {
    function LifecheqBaseSummaryComponent(dialog, document) {
        this.dialog = dialog;
        this.document = document;
        this.add = new EventEmitter();
        this.edit = new EventEmitter();
        this.remove = new EventEmitter();
        this.addonsList = [];
        this.payoutTypeList = [];
        this.payoutTermList = [];
        this.payoutAnnuityAmountList = [];
        this.monthlySupportSelected = false;
        this.payoutTerm = 12;
        this.payoutType = 'annuity';
        this.payoutAnnuityAmount = 1000;
        this.errorSubject = new BehaviorSubject([]);
        this.errors$ = this.errorSubject.asObservable();
    }
    LifecheqBaseSummaryComponent.removeDependant = function (dependants, id) {
        var list = [];
        dependants.forEach(function (d) {
            if (d.id !== id) {
                list.push(d);
            }
        });
        return list;
    };
    LifecheqBaseSummaryComponent.prototype.ngAfterViewChecked = function () {
        var _this = this;
        this.errors$.subscribe(function (value) {
            _this.document.querySelector('[data-event-name=\'Action editPolicy-0 Next\']').disabled = value.length !== 0;
        });
    };
    LifecheqBaseSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('=============================================');
        console.log(this.dependants);
        console.log('=============================================');
        this.addonsList = [
            {
                'id': 'KP_LPF_UCB2_001',
                'code': 'LPF_UCB2',
                'name': 'Pantheon Funeral Cover - Upgraded Cash Back 2',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_UCB1_001',
                'code': 'LPF_UCB1',
                'name': 'Pantheon Funeral Cover - New Year Benefit',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_S_MS_001',
                'code': 'LPF_S_MS',
                'name': 'Pantheon Funeral Cover - Spouse Monthly Support',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_RB_001',
                'code': 'LPF_RB',
                'name': 'Pantheon Funeral Cover - Retirement Benefit',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_PW1_001',
                'code': 'LPF_PW1',
                'name': 'Pantheon Funeral Cover - Premium Waiver',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_UCB3_001',
                'code': 'LPF_UCB3',
                'name': 'Pantheon Funeral Cover - Upgraded Cash Back 3',
                'type': 'primary',
                checked: false,
                disabled: false
            },
            {
                'id': 'KP_LPF_REPAT_001',
                'code': 'LPF_REPAT',
                'name': 'Pantheon Funeral Cover - Repatriation Benefit',
                'type': 'primary',
                checked: true,
                disabled: true
            },
            {
                'id': 'KP_LPF_M_MS_001',
                'code': 'LPF_M_MS',
                'name': 'Pantheon Funeral Cover - Main Member Monthly Support',
                'type': 'primary',
                checked: false,
                disabled: false
            }
        ];
        this.payoutTypeList = [
            {
                name: 'Annuity',
                value: 'annuity'
            },
            {
                name: 'Blended',
                value: 'blended'
            }
        ];
        this.payoutTermList = [
            {
                name: '12 Months',
                value: 12
            },
            {
                name: '24 Months',
                value: 24
            },
            {
                name: '36 Months',
                value: 36
            }
        ];
        this.payoutAnnuityAmountList = [
            {
                name: 'R1000',
                value: 1000
            },
            {
                name: 'R2000',
                value: 2000
            }
        ];
        this.mainDependant = this.dependants.find(function (dependant) { return dependant.product === 'LPF'; });
        this.spouseDependant = this.dependants.find(function (dependant) { return dependant.product === 'LPF_S'; });
        var mmId = new RsaId();
        mmId.setId(this.mainDependant.id_or_dob);
        this.mainDependant.age = mmId.getAge();
        if (!this.dependants.some(function (dependant) { return dependant.product === 'LPF_S'; })) {
            this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].disabled = true;
            this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].checked = false;
        }
        this.mainDependant.addons.forEach(function (addon) {
            _this.addonsList.forEach(function (addonItem, index) {
                if (addonItem.code === addon.addon_code) {
                    _this.addonsList[index].checked = true;
                    if (addonItem.code === 'LPF_UCB1') {
                        _this.addonsList[_this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB2'; })].disabled = true;
                        _this.addonsList[_this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB3'; })].disabled = true;
                    }
                    else if (addonItem.code === 'LPF_UCB2') {
                        _this.addonsList[_this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB1'; })].disabled = true;
                        _this.addonsList[_this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB3'; })].disabled = true;
                    }
                    else if (addonItem.code === 'LPF_UCB3') {
                        _this.addonsList[_this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB1'; })].disabled = true;
                        _this.addonsList[_this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB2'; })].disabled = true;
                    }
                    if (addonItem.code === 'LPF_RB') {
                        if ((_this.mainDependant.age + 1) > 65) {
                            // this.addError('You\'re not eligible for the Retirement Benefit');
                            _this.mainDependant.addons = _this.mainDependant.addons.filter(function (aI) { return aI.addon_code !== 'LPF_RB'; });
                            _this.addonsList[index].disabled = true;
                            _this.addonsList[index].checked = false;
                        }
                    }
                    if (addonItem.code === 'LPF_PW1') {
                        if (_this.mainDependant.age === 65 || (_this.mainDependant.age + 1) > 63) {
                            _this.addonsList[index].disabled = true;
                            _this.addonsList[index].checked = false;
                            _this.mainDependant.addons = _this.mainDependant.addons.filter(function (aI) { return aI.addon_code !== 'LPF_PW'; });
                        }
                    }
                }
            });
        });
        if ((this.mainDependant.payout_type !== 'lump-sum')) {
            this.monthlySupportSelected = true;
            this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_M_MS'; })].checked = true;
            this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].disabled = true;
            this.payoutType = this.mainDependant.payout_type;
            this.payoutTerm = this.mainDependant.payout_term;
            this.payoutAnnuityAmount = this.mainDependant.payout_annuity_amount;
        }
        else if (!isNullOrUndefined(this.spouseDependant) && this.spouseDependant.payout_type !== 'lump-sum') {
            this.monthlySupportSelected = true;
            this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].checked = true;
            this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_M_MS'; })].disabled = true;
            this.payoutType = this.spouseDependant.payout_type;
            this.payoutTerm = this.spouseDependant.payout_term;
            this.payoutAnnuityAmount = this.spouseDependant.payout_annuity_amount;
        }
        // this.checkMaxCover();
        this.checkCashBackAddon();
    };
    LifecheqBaseSummaryComponent.prototype.onChangeAddon = function (_a) {
        var source = _a.source, checked = _a.checked;
        var addonVO = new AddonServerVO();
        console.log(source, checked);
        if (checked) {
            if (source.value === 'LPF_UCB1') {
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB2'; })].disabled = true;
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB3'; })].disabled = true;
            }
            else if (source.value === 'LPF_UCB2') {
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB1'; })].disabled = true;
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB3'; })].disabled = true;
            }
            else if (source.value === 'LPF_UCB3') {
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB1'; })].disabled = true;
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB2'; })].disabled = true;
            }
            if (source.value === 'LPF_M_MS' || source.value === 'LPF_S_MS') {
                this.monthlySupportSelected = true;
                if (source.value === 'LPF_M_MS') {
                    this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].disabled = true;
                    this.mainDependant.payout_type = this.payoutType;
                    this.mainDependant.payout_term = this.payoutTerm;
                    this.mainDependant.payout_annuity_amount = this.payoutAnnuityAmount;
                }
                else if (source.value === 'LPF_S_MS') {
                    this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_M_MS'; })].disabled = true;
                    this.spouseDependant.payout_type = this.payoutType;
                    this.spouseDependant.payout_term = this.payoutTerm;
                    this.spouseDependant.payout_annuity_amount = this.payoutAnnuityAmount;
                }
                if (!this.checkMonthlySupportAddonValid(source.value)) {
                    if (source.value === 'LPF_M_MS') {
                        this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].disabled = true;
                        this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].checked = false;
                    }
                    else if (source.value === 'LPF_S_MS') {
                        this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_M_MS'; })].disabled = true;
                        this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_M_MS'; })].checked = false;
                    }
                    this.mainDependant.addons = this.mainDependant.addons.filter(function (addon) { return addon.addon_code !== source.value; });
                }
            }
            if (source.value !== 'LPF_M_MS' && source.value !== 'LPF_S_MS') {
                addonVO.sales_process_id = this.spId;
                // addonVO.id = null;
                addonVO.type = 'addon';
                addonVO.name = this.addonsList.find(function (addonItem) { return addonItem.code === source.value; }).name;
                addonVO.product_code = 'LPF';
                addonVO.product_master_id = this.addonsList.find(function (addonItem) { return addonItem.code === source.value; }).id;
                addonVO.addon_code = source.value;
                addonVO.benefit_amount = 0;
                addonVO.premium_amount = 0;
                addonVO.addon_version = this.mainDependant.pricing_version;
                this.mainDependant.addons = this.mainDependant.addons.concat([addonVO]);
            }
            this.checkCashBackAddon();
        }
        else if (!checked) {
            if (source.value === 'LPF_UCB1') {
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB2'; })].disabled = false;
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB3'; })].disabled = false;
            }
            else if (source.value === 'LPF_UCB2') {
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB1'; })].disabled = false;
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB3'; })].disabled = false;
            }
            else if (source.value === 'LPF_UCB3') {
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB1'; })].disabled = false;
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_UCB2'; })].disabled = false;
            }
            if (source.value === 'LPF_M_MS') {
                if (this.dependants.some(function (dependant) { return dependant.product === 'LPF_S'; })) {
                    this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].disabled = false;
                }
                this.mainDependant.payout_type = 'lump-sum';
                this.mainDependant.payout_term = 0;
                this.mainDependant.payout_annuity_amount = null;
            }
            else if (source.value === 'LPF_S_MS') {
                this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_M_MS'; })].disabled = false;
                this.spouseDependant.payout_type = 'lump-sum';
                this.spouseDependant.payout_term = 0;
                this.spouseDependant.payout_annuity_amount = null;
            }
            if (source.value === 'LPF_M_MS' || source.value === 'LPF_S_MS') {
                this.monthlySupportSelected = false;
            }
            this.mainDependant.addons = this.mainDependant.addons.filter(function (addon) { return addon.addon_code !== source.value; });
            this.checkCashBackAddon();
        }
        console.log('=============================================');
        console.log(this.mainDependant);
        // console.log(this.spouseDependant);
        console.log('=============================================');
        this.edit.emit({ main: true, dependant: this.mainDependant });
        if (!isNullOrUndefined(this.spouseDependant)) {
            this.edit.emit({ main: false, dependant: this.spouseDependant });
        }
        this.remove.emit(null);
    };
    LifecheqBaseSummaryComponent.prototype.onMonthlySupportOptionChange = function (_a, option) {
        var source = _a.source, value = _a.value;
        switch (option) {
            case 'payoutType':
                {
                    this.payoutType = value;
                }
                break;
            case 'payoutTerm':
                {
                    this.payoutTerm = value;
                }
                break;
            case 'payoutAnnuityAmount':
                {
                    this.payoutAnnuityAmount = value;
                }
                break;
        }
        if (this.addonsList.find(function (addon) { return addon.code === 'LPF_M_MS'; }).checked) {
            if (this.checkMonthlySupportAddonValid('LPF_M_MS')) {
                this.removeError('Total monthly support can\'t be more than the cover amount');
                this.mainDependant.payout_type = this.payoutType;
                this.mainDependant.payout_term = this.payoutTerm;
                this.mainDependant.payout_annuity_amount = this.payoutAnnuityAmount;
            }
            console.log('=============================================');
            console.log(this.mainDependant);
            console.log('=============================================');
        }
        else if (this.addonsList.find(function (addon) { return addon.code === 'LPF_S_MS'; }).checked) {
            this.removeError('Total monthly support can\'t be more than the cover amount');
            if (this.checkMonthlySupportAddonValid('LPF_S_MS')) {
                this.spouseDependant.payout_type = this.payoutType;
                this.spouseDependant.payout_term = this.payoutTerm;
                this.spouseDependant.payout_annuity_amount = this.payoutAnnuityAmount;
            }
            console.log('=============================================');
            console.log(this.spouseDependant);
            console.log('=============================================');
        }
        this.edit.emit({ main: true, dependant: this.mainDependant });
        this.edit.emit({ main: false, dependant: this.spouseDependant });
    };
    LifecheqBaseSummaryComponent.prototype.openModal = function (dependant, edit, main) {
        var _this = this;
        var data = {
            spId: this.spId,
            benefitId: this.benefitId,
            leadName: this.leadName,
            edit: edit,
            main: main,
            dependant: dependant,
            products: this.products,
            dependants: this.dependants
        };
        this.dialogRef = this.dialog.open(LifecheqDependantModalComponent, {
            disableClose: true,
            panelClass: 'dl-modal',
            maxWidth: '99vw',
            position: { top: '20px' },
            data: data
        });
        this.dialogRef.beforeClose().subscribe(function (res) {
            console.log('RES', res);
            if (res instanceof ModalData) {
                if (res.dependant.product === 'LPF_S') {
                    _this.addonsList[_this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].disabled = false;
                }
                if (['LPF_S', 'LPF_C', 'LPF_P', 'LPF_E'].includes(res.dependant.product)) {
                    res.dependant.payout_type = 'lump-sum';
                    res.dependant.payout_term = 0;
                    res.dependant.payout_annuity_amount = null;
                    res.dependant.orig_waiting_period_served = _this.mainDependant.orig_waiting_period_served;
                    res.dependant.orig_waiting_period = _this.mainDependant.orig_waiting_period;
                    res.dependant.orig_waiting_period_started = _this.mainDependant.orig_waiting_period_started;
                    res.dependant.pricing_version = _this.mainDependant.pricing_version;
                    res.dependant.addons = [];
                    res.dependant.pricing.pricing_version = _this.mainDependant.pricing_version;
                    res.dependant.pricing.included = true;
                    res.dependant.pricing.starting_age_year = res.dependant.age;
                    res.dependant.pricing.sum_assured_increase = 0.03;
                    res.dependant.pricing.premium_increase = 0.05;
                }
                if (res.dependant.product === 'LPF_S') {
                    _this.spouseDependant = res.dependant;
                }
                // res.dependant = dependant;
                if (res.edit) {
                    console.log(res);
                    _this.edit.emit({ main: res.main, dependant: res.dependant });
                }
                else {
                    console.log(res);
                    _this.add.emit(res.dependant);
                }
                // this.checkMaxCover();
            }
        });
    };
    LifecheqBaseSummaryComponent.prototype.addError = function (value) {
        this.errorSubject.next(this.errorSubject.value.filter(function (error) { return error !== value; }).concat([value]));
    };
    LifecheqBaseSummaryComponent.prototype.removeError = function (value) {
        this.errorSubject.next(this.errorSubject.value.filter(function (error) { return error !== value; }));
    };
    LifecheqBaseSummaryComponent.prototype.checkMaxCover = function () {
        var totalCover = 0;
        this.dependants.forEach(function (dependantVal) { return totalCover += dependantVal.benefit_amount; });
        if (totalCover > 100000) {
            this.addError('Total cover cannot exceed R100,000');
        }
        else {
            this.removeError('Total cover cannot exceed R100,000');
        }
    };
    LifecheqBaseSummaryComponent.prototype.checkCashBackAddon = function () {
        if (this.mainDependant.addons.some(function (addon) { return addon.addon_code === 'LPF_UCB1' || addon.addon_code === 'LPF_UCB2' || addon.addon_code === 'LPF_UCB3'; })) {
            this.removeError('At least one Upgraded Cash Back benefit is required');
        }
        else {
            this.addError('At least one Upgraded Cash Back benefit is required');
        }
    };
    LifecheqBaseSummaryComponent.prototype.checkMonthlySupportAddonValid = function (addonCode) {
        if ((addonCode === 'LPF_M_MS' && this.mainDependant.benefit_amount < this.payoutTerm * this.payoutAnnuityAmount) || (addonCode === 'LPF_S_MS' && this.spouseDependant.benefit_amount < this.payoutTerm * this.payoutAnnuityAmount)) {
            this.addError('Total monthly support can\'t be more than the cover amount');
            return false;
        }
        return true;
    };
    LifecheqBaseSummaryComponent.prototype.clickAdd = function () {
        this.openModal(new LPFDependantVO(), false, false);
    };
    LifecheqBaseSummaryComponent.prototype.clickEdit = function (dependant, main) {
        this.openModal(dependant, true, main);
    };
    LifecheqBaseSummaryComponent.prototype.clickRemove = function (dependant) {
        dependant.deleting = true;
        console.log('REMOVE DEPENDENT BASE SUMMARY ONE');
        if (dependant.product === 'LPF_S') {
            this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].disabled = true;
            this.addonsList[this.addonsList.findIndex(function (aI) { return aI.code === 'LPF_S_MS'; })].checked = false;
            this.monthlySupportSelected = false;
            this.mainDependant.addons = this.mainDependant.addons.filter(function (addon) { return addon.addon_code !== dependant.product; });
        }
        console.log('REMOVE DEPENDENT BASE SUMMARY');
        this.remove.emit(dependant);
    };
    LifecheqBaseSummaryComponent.prototype.getBadge = function (d) {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel.substr(0, 1);
        }
        return '?';
    };
    LifecheqBaseSummaryComponent.prototype.getHint = function (d) {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel;
        }
        return 'Unknown';
    };
    Object.defineProperty(LifecheqBaseSummaryComponent.prototype, "totalPremium", {
        get: function () {
            var total = 0;
            if (!isNullOrUndefined(this.dependants)) {
                this.dependants.forEach(function (d) {
                    total += d.premium_amount;
                });
            }
            return total;
        },
        enumerable: true,
        configurable: true
    });
    return LifecheqBaseSummaryComponent;
}());
export { LifecheqBaseSummaryComponent };
