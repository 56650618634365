import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DependantVO, ModalData} from './sf-summary.service';
import {MatDialog} from '@angular/material';
import {DropDownValuesVO} from "../select.vo";
import {MatDialogRef} from "@angular/material/dialog";
import {isNullOrUndefined} from "util";
import {SFDependantModalComponent} from "./sf-dependant-modal.component";
import {Log} from "ng2-logger/client";
import {forEach} from "@angular/router/src/utils/collection";

const log = Log.create('SF-Summary');
@Component({
    templateUrl: 'sf-base-summary.component.html',
    selector: 'sf-base-summary'
})

export class SFBaseSummaryComponent{
    @Input()
    leadName: string;
    @Input()
    spId: string;
    @Input()
    benefitId: string;
    @Input()
    dependants: DependantVO[];
    @Input()
    products: DropDownValuesVO[];
    @Input()
    eventCategory: string;
    @Output()
    add = new EventEmitter<DependantVO>();
    @Output()
    edit = new EventEmitter<any>();
    @Output()
    remove = new EventEmitter<DependantVO>();
    private dialogRef: MatDialogRef<SFDependantModalComponent>;
    @Output()
    revert = new EventEmitter<DependantVO[]>();

    constructor(private dialog: MatDialog) {
    }

    public openModal(dependant: DependantVO, edit: boolean, main: boolean): void {
        const data: ModalData = {
            spId: this.spId,
            benefitId: this.benefitId,
            leadName: this.leadName,
            edit: edit,
            main: main,
            dependant: dependant,
            products: this.products,
            dependants: this.dependants
        };
        this.dialogRef = this.dialog.open(SFDependantModalComponent,
            {
                disableClose: true,
                panelClass: 'dl-modal',
                maxWidth: '99vw',
                position: {top: '20px'},
                data: data
            }
        );
        this.dialogRef.beforeClose().subscribe((res: any) => {
            if (res instanceof ModalData) {
                if (res.edit) {
                    this.edit.emit({main: res.main, dependant: res.dependant});
                } else {
                    this.add.emit(res.dependant);
                }
            }
        });
    }

    clickAdd(): void {
        this.openModal(new DependantVO(), false, false);
    }

    clickEdit(dependant: DependantVO, main: boolean){
        this.openModal(dependant, true, main);
    }

    public static removeDependant(dependants: DependantVO[], id: string): DependantVO[]{
        const list: DependantVO[] = [];
        dependants.forEach((d) => {
            if (d.id !== id) {
                list.push(d);
            }
        });
        return list;
    }

    canRevert() :boolean{
        let valid: boolean = true;
        if (!isNullOrUndefined(this.dependants)) {
            this.dependants.forEach((d) => {
                if(d.pricing_version === 'DEFAULT')
                    valid = false;
            });
        }
        return valid;
    }

    clickRemove(dependant: DependantVO) {
        dependant.deleting = true;
        this.remove.emit(dependant);
    }

    getBadge(d: DependantVO): string {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel.substr(0, 1);
        }
        return '?';
    }

    getHint(d: DependantVO): string {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel;
        }
        return 'Unknown';
    }

    get totalPremium(): number {
        let total = 0;
        if (!isNullOrUndefined(this.dependants)) {
            this.dependants.forEach((d) => {
                total += d.premium_amount;
            });
        }
        return total;
    }

    clickRevert(){
        this.revert.emit();
    }


}
